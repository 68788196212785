
import { Table, TableData, TableHeader, TableRow } from '@/components/table'
import { CategoryModel } from 'tradingmate-modules/src/models/api/categorisation'
import { Services } from 'tradingmate-modules/src/services'
import { Vue, Component } from 'vue-property-decorator'

@Component({
  components: {
    Table,
    TableRow,
    TableData,
    TableHeader
  }
})

export default class AdminCategoryIndex extends Vue {
  private baseCategories: CategoryModel[] | null = null

  mounted (): void {
    this.getBaseCategories()
  }

  getBaseCategories (): void {
    Services.API.Admin.Categories.GetBaseCategories()
      .then((categories) => { this.baseCategories = categories })
  }

  handleNewBaseCategory (): void {
    this.$router.push('/admin/categories/new')
  }

  handleCategoryClick (categoryId: string): void {
    this.$router.push(`/admin/category/${categoryId}`)
  }
}

